import HotTable, {HotColumn} from "@handsontable/react";
import {ReactElement, useEffect, useRef, useState} from "react";
import css from "../../pages/portfolio-financial/styles/css.module.scss";
import {LICENSES} from "../../constants/Licenses";
import {FinancialEntityType} from "../../__generated__/generated_types";
import {TDataRecord, TTableRow} from "./types";
import {MONTHS} from "../../constants/Months";
import {TAccountPropertiesLoader} from "./accountPropertiesDataLoader";
import {DescriptionCellRenderer} from "./DescriptionCellRenderer";
import {DataCellRenderer} from "./DataCellRenderer";
import {mapValues} from "./logic";
import {nextSortType, SortType} from "../../utils/sorting";
import {formatterGlName} from "../../utils/formatters";

export type INumbersTable = {
    lowestReforecastStartMonth: number | undefined;
    highestReforecastStartMonth: number | undefined;
    displayRows: TTableRow[] | undefined;
    glTotalRow?: TTableRow | undefined;
    enableSortButton?: boolean;
    budgetYear: number;
    accountPropertiesLoader: TAccountPropertiesLoader;
    accountsData: Record<string, TDataRecord>;
    collapsedRowIds: string[] | undefined;
    isZeroSuppressed: boolean;
    handleRowHeaderClicked?: (tableRow: TTableRow) => void;
    handleGLButtonClicked?: (tableRow: TTableRow) => void;
    firstColumnLabel?: string;
    disableIndentPropertyName?: boolean;
};

const COLWIDTHS = [300, ...new Array(28).fill(110)];

function parseHotDataString(hotDataString: string | undefined): number {
    if (!hotDataString) {
        return 0;
    }
    let parsed = 0;
    if (hotDataString.includes("$")) {
        parsed = parseFloat(hotDataString.replace("$", "").replace(/,/g, ""));
    }
    else if (hotDataString.includes("%")) {
        parsed = parseFloat(hotDataString.replace("%", ""));
    }
    if (isNaN(parsed)) {
        parsed = 0;
    }
    return parsed;
}

export function NumbersTable(props: INumbersTable): ReactElement | null {
    const {lowestReforecastStartMonth, highestReforecastStartMonth,
        displayRows, accountsData,
        collapsedRowIds, isZeroSuppressed,
        handleRowHeaderClicked,
        handleGLButtonClicked
    } = props;
    // displayingRows contains subset of displayRows
    // once we introduced supressing zeroes - we broke the concept of displayRows always 1-1 match HOT rows
    // the displayingRows is now intended to be used for identifying table row based on HOT row
    const [displayingRows, setDisplayingRows] = useState<TTableRow[]>();
    const {loadDataForAccount: loadPropertiesValuesForAccount,
        data: accountPropertiesValues} = props.accountPropertiesLoader;

    const [hotData, setHotData] = useState<string[][]>([]);
    const hotRef = useRef<HotTable>(null);
    const [sortColumn, setSortColumn] = useState<number>();
    const [sortType, setSortType] = useState<SortType>();

    const COLHEADERS: string[] = [
        props.firstColumnLabel ?? "Description",
        ...MONTHS.map(m => `
            <span>${(props.budgetYear - 1) % 100}</span>
            <span>${m}</span>
        `),
        ...MONTHS.map(m => `
            <span>${props.budgetYear % 100}</span>
            <span>${m}</span>
        `),
        `
            <span>${(props.budgetYear - 1) % 100}</span>
            <span>Total</span>
        `,
        `
            <span>${props.budgetYear % 100}</span>
            <span>Total</span>
        `,
        "Var&nbsp;$",
        "Var&nbsp;%"
    ];

    useEffect(() => {
        if (!displayRows) {
            return;
        }

        const hotData = [] as string[][];
        const displayingRows: TTableRow[] = [];
        for (const row of displayRows) {
            let values: string[] = new Array(28).fill(null);
            let name: string | undefined = undefined;
            if (row.isPropertyRow) {
                name = row.name as string;
                const accountId = row.parentIds[0];
                if (accountId) {
                    const accountPropertiesData = accountPropertiesValues[accountId];
                    if (accountPropertiesData) {
                        values = mapValues(accountPropertiesData[row.id]);
                    }
                    else {
                        loadPropertiesValuesForAccount(accountId);
                    }
                }
            }
            else {
                const {name: glName, number: glNumber} = row.name as {name: string, number: string;};
                const dbData = accountsData[row.id];
                name = formatterGlName(glName, glNumber);
                if (row.isSubtotalRow) {
                    name = "Total " + name;
                }
                else if (row.type !== FinancialEntityType.Component
                    && row.type !== FinancialEntityType.Account
                    && !row.isPropertyRow
                ) {
                    name = (collapsedRowIds?.includes(row.id) ? "+ " : "- ") + name;
                }
                if (dbData) {
                    values = mapValues(dbData.values);
                }
            }
            // gives the indentation for nested rows
            let propertyRowIndent = 0;

            if (!props.disableIndentPropertyName) {
                const extraPropertyRowIndent = row.isPropertyRow ? 10 : 0;
                propertyRowIndent = row.parentIds.length * 10 + extraPropertyRowIndent;
            }
            name = `<span style='padding-left: ${propertyRowIndent}px'>${name}</span>`;

            if (!collapsedRowIds?.includes(row.id) && !row.isSubtotalRow && !row.isPropertyRow) {
                values = new Array(28).fill("");
            }

            if (isZeroSuppressed && values.every(val => val === "$0" || val === "0%" || val === "0" || val === null)) {
                continue;
            }

            hotData.push([name ?? "", ...values]);
            displayingRows.push(row);
        }

        if (props.glTotalRow) {
            const {name: glName, number: glNumber} = props.glTotalRow.name as {name: string, number: string;};
            const dbData = accountsData[props.glTotalRow.id];
            const name = formatterGlName(glName, glNumber);
            if (dbData) {
                const values = mapValues(dbData.values);
                hotData.push([name, ...values]);
                displayingRows.push(props.glTotalRow);
            }
        }

        hotData.unshift(COLHEADERS);

        setHotData(hotData);
        setDisplayingRows(displayingRows);
    }, [accountsData, displayRows, accountPropertiesValues, isZeroSuppressed]);

    function doSort(columnIndex: number, sortType: SortType) {
        if (!displayingRows) {
            return;
        }
        const dataWithOriginalIndicies = hotData.slice(1, props.glTotalRow ? hotData.length - 1 : undefined).map((row, index) => ({row, index}));
        dataWithOriginalIndicies.sort((a, b) => {
            const aVal = columnIndex === 0 ? a.row[columnIndex] ?? "" : parseHotDataString(a.row[columnIndex]);
            const bVal = columnIndex === 0 ? b.row[columnIndex] ?? "": parseHotDataString(b.row[columnIndex]);
            if (aVal === bVal) {
                return 0;
            }
            if (sortType === "desc") {
                return aVal > bVal ? -1 : 1;
            }
            else {
                return aVal < bVal ? -1 : 1;
            }
        });
        const displayingRowsByIndex = new Map<number, TTableRow>();
        displayingRows.forEach((row, index) => displayingRowsByIndex.set(index, row));
        const updatedDisplayingRows:TTableRow[] = [];
        const updatedHotData = [COLHEADERS];
        dataWithOriginalIndicies.forEach(({row, index}) => {
            updatedDisplayingRows.push(displayingRowsByIndex.get(index) as TTableRow);
            updatedHotData.push(row);
        });
        if (props.glTotalRow) {
            updatedHotData.push(hotData.lastElement);
            updatedDisplayingRows.push(props.glTotalRow);
        }
        setHotData(updatedHotData);
        setDisplayingRows(updatedDisplayingRows);
    }

    function handleSortClick(columnIndex: number) {
        let newSortColumn = sortColumn;
        let newSortType = sortType;
        if (sortColumn !== columnIndex) {
            newSortColumn = columnIndex;
            newSortType = nextSortType(undefined);
        }
        else {
            newSortType = nextSortType(sortType);
        }
        if (newSortType !== undefined && newSortColumn !== undefined) {
            doSort(newSortColumn, newSortType);
        }
        else {
            doSort(0, "asc");
        }
        setSortColumn(newSortColumn);
        setSortType(newSortType);
    }

    function handleHotCellClick(tableRow: TTableRow) {
        if (handleRowHeaderClicked === undefined) {
            return;
        }
        handleRowHeaderClicked(tableRow);
    }

    function handleDescriptionCellButtonClicked(tableRow: TTableRow) {
        if (handleGLButtonClicked) {
            handleGLButtonClicked(tableRow);
        }
    }

    return (
        <>
            <HotTable
                tableClassName={css.table}
                data={hotData}
                ref={hotRef}
                settings={{
                    autoRowSize: false,
                    autoColumnSize: false,
                    width: "100%",
                    height: "100%",
                    fixedRowsTop: 1,
                    fixedRowsBottom: props.glTotalRow ? 1 : undefined,
                    fixedColumnsLeft: 1,
                    readOnly: true,
                    rowHeights: 60,
                    colWidths: COLWIDTHS,
                    licenseKey: LICENSES.HandsOnTable,
                }}
            >
                <HotColumn>
                    <DescriptionCellRenderer hot-renderer
                        displayingRows={displayingRows}
                        onButtonClicked={handleDescriptionCellButtonClicked}
                        onCellClicked={handleHotCellClick}
                        disableButton={handleGLButtonClicked === undefined}
                        glTotalRowId={props.glTotalRow?.id}
                    />
                </HotColumn>
                {COLHEADERS.slice(1).map((_, index) =>
                    <HotColumn key={index}>
                        <DataCellRenderer hot-renderer
                            lowestReforecastStartMonth={lowestReforecastStartMonth}
                            highestReforecastStartMonth={highestReforecastStartMonth}
                            displayingRows={displayingRows}
                            glTotalRowId={props.glTotalRow?.id}
                            enableSortButton={props.enableSortButton}
                            sort={() => handleSortClick(index + 1)}
                            sortType={sortType}
                            sortColumn={sortColumn}
                        />
                    </HotColumn>
                )}
            </HotTable>
        </>
    );
}