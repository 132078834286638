import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";
import AdminHeader from "../admin/AdminHeader";
import * as css from "./styles/unitTypeManagement.module.scss";
import useAppStore from "../../hooks/useAppStore";

import {Field, MediaInput} from '@zendeskgarden/react-forms';
import {Button} from "@zendeskgarden/react-buttons";

import "native-injects";
import Card from "../simplified-revenue/components/Card";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import {UnitTypeForPmsActualOverridesUpdateInput, UnitTypePmsActualOverrideMonthModel, useAddUnitTypeMutation, useDeleteUnitTypeMutation, useListUnitTypesLazyQuery, useSetUnitTypePmsOverridesMutation, useUpdateUnitTypeMutation} from "../../__generated__/generated_types";
import {ReactComponent as SearchIcon} from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import {ReactComponent as CheckIcon} from '@zendeskgarden/svg-icons/src/16/check-lg-fill.svg';
import {ReactComponent as PencilIcon} from '@zendeskgarden/svg-icons/src/16/pencil-stroke.svg';
import {ReactComponent as Icon123} from '@zendeskgarden/svg-icons/src/16/123-stroke.svg';
import {ReactComponent as XIcon} from '@zendeskgarden/svg-icons/src/16/x-stroke.svg';
import {ReactComponent as PlusIcon} from '@zendeskgarden/svg-icons/src/16/plus-stroke.svg';
import {ReactComponent as AlertIcon} from '@zendeskgarden/svg-icons/src/16/alert-warning-fill.svg';
import {EditUnitType} from "./components/EditUnitType";
import {toast} from "react-toastify";
import DeleteUnitTypeModal from "./components/DeleteUnitTypeModal";
import {Tooltip} from "@zendeskgarden/react-tooltips";
import {Body, Cell, Head, HeaderCell, HeaderRow, Row, Table} from "@zendeskgarden/react-tables";
import {EditUnitTypeNumbers} from "./components/EditUnitTypeNumbers";
import {UnitTypeInfo, UnitTypePMSInfo} from "./types";

type MetricMapValue = {original: number | null, override: number | null;};
type MetricMap = Map<string, MetricMapValue>;

function dataValueToMapValue(
    dataValue: Pick<UnitTypePmsActualOverrideMonthModel,
        'unitTypeId' | 'unitTypeName' | 'original' | 'override'>
): MetricMapValue {
    return {original: dataValue.original ?? null, override: dataValue.override ?? null};
}

export default function UnitTypeManagement(): ReactElement {
    const appStore = useAppStore();
    const {currentProperty} = useProperties();

    const [getUnitTypes, {data: unitTypesData, loading: unitTypesDataLoading}] = useListUnitTypesLazyQuery({fetchPolicy: "no-cache"});
    const [deleteUnitType] = useDeleteUnitTypeMutation({notifyOnNetworkStatusChange: true});
    const [updateUnitType] = useUpdateUnitTypeMutation({notifyOnNetworkStatusChange: true});
    const [updateUnitTypeOverrides] = useSetUnitTypePmsOverridesMutation();
    const [addUnitType] = useAddUnitTypeMutation({notifyOnNetworkStatusChange: true});
    const bodyRef = useRef(null);

    const [unitTypes, setUnitTypes] = useState<UnitTypeInfo[]>([]);
    const [unitTypesDisplay, setUnitTypesDisplay] = useState<UnitTypeInfo[]>([]);
    const [unitTypesPMSLatest, setUnitTypesPMSLatest] = useState<{list: UnitTypePMSInfo[], date: string;}>();
    const [metricValues, setMetricValues] = useState<{
        unitCount: MetricMap,
        inPlaceRent: MetricMap,
        occupancy: MetricMap;
    }>({
        unitCount: new Map(),
        inPlaceRent: new Map(),
        occupancy: new Map()
    });
    const [updateUnitTypeData, setUpdateUnitTypeData] = useState<{id: string, name: string;}>();
    const [deleteUnitTypeData, setDeleteUnitTypeData] = useState<{id: string, name: string;}>();
    const [updateUnitTypeNumbers, setUpdateUnitTypeNumbers] = useState<{id: string, name: string;}>();
    const [addUnitTypeData, setAddUnitTypeData] = useState(false);

    function fetchUnitTypesData() {
        if (currentProperty) {
            let lastActualMonthIndex = currentProperty.reforecastStartMonthIndex - 1;
            if (lastActualMonthIndex < 0) {
                lastActualMonthIndex = 11;
            }
            getUnitTypes({variables: {propertyId: currentProperty.id, monthIndex: lastActualMonthIndex}});
        }
    }

    function handleUpdateUnitType(
        unitTypeId: string,
        name: string | undefined,
        unitCountOverrides: Record<number, number | null>,
        occupancyOverrides: Record<number, number | null>,
        inPlaceRentOverrides: Record<number, number | null>
    ) {
        const promises = [];
        if (name !== undefined) {
            promises.push(new Promise((resolve, reject) => {
                updateUnitType({
                    variables: {
                        unitTypeId: unitTypeId,
                        name: name
                    }
                })
                    .then(() => resolve(true), () => reject())
                    .catch(() => reject());
            }));
        }
        if (
            currentProperty && (
                Object.entries(unitCountOverrides).length > 0 ||
                Object.entries(occupancyOverrides).length > 0 ||
                Object.entries(inPlaceRentOverrides).length > 0
            )) {
            const unitCountUpdates: UnitTypeForPmsActualOverridesUpdateInput[] = [];
            const occupancyUpdates: UnitTypeForPmsActualOverridesUpdateInput[] = [];
            const inPlaceRentUpdates: UnitTypeForPmsActualOverridesUpdateInput[] = [];
            for (const [monthIndex, val] of Object.entries(unitCountOverrides)) {
                unitCountUpdates.push({
                    unitTypeId: unitTypeId,
                    [`valueM${monthIndex}`]: val
                });
            }
            for (const [monthIndex, val] of Object.entries(occupancyOverrides)) {
                occupancyUpdates.push({
                    unitTypeId: unitTypeId,
                    [`valueM${monthIndex}`]: val
                });
            }
            for (const [monthIndex, val] of Object.entries(inPlaceRentOverrides)) {
                inPlaceRentUpdates.push({
                    unitTypeId: unitTypeId,
                    [`valueM${monthIndex}`]: val
                });
            }
            promises.push(new Promise((resolve, reject) => {
                updateUnitTypeOverrides({
                    variables: {
                        propertyId: currentProperty.id,
                        unitCount: unitCountUpdates,
                        occupancy: occupancyUpdates,
                        inPlaceRent: inPlaceRentUpdates
                    }
                })
                    .then(() => resolve(true), () => reject())
                    .catch(() => reject());
            }));
        }
        if (promises.length > 0) {
            Promise.all(promises)
                .then(
                    () => {
                        setUpdateUnitTypeData(undefined);
                        setUpdateUnitTypeNumbers(undefined);
                        toast.success(`Unit Type update complete`);
                        fetchUnitTypesData();
                    }
                );
        }
    }

    function handleDeleteUnitType(unitTypeId: string) {
        deleteUnitType({
            variables: {
                unitTypeId: unitTypeId
            }
        })
            .then(
                (data) => {
                    setDeleteUnitTypeData(undefined);
                    if (data.data?.deleteUnitType) {
                        toast.success(`Unit Type deleted successfully`);
                    }
                    fetchUnitTypesData();
                }
            );
    }

    function handleAddUnitType(propertyId: string, name: string | undefined) {
        if (!name) {
            return;
        }
        addUnitType({
            variables: {
                propertyId: propertyId,
                name: name
            }
        })
            .then(
                (data) => {
                    setAddUnitTypeData(false);
                    if (data.data?.addUnitType) {
                        toast.success(`Unit Type added successfully`);
                    }
                    fetchUnitTypesData();
                }
            );
    }


    function handleIntercomMismatchingProperty(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (window.Intercom !== undefined && currentProperty) {
            const body = `The ${currentProperty.name} was not found in Property Management System. Please help resolving the issue.`;
            // NOTE: Pre-populating messages is only possible with Intercom Inbox Essential & Pro product.
            window.Intercom("showNewMessage", body);
            e.preventDefault();
        }
    }


    useEffect(
        () => {
            appStore.set({isLoading: false});
            return () => undefined;
        },
        []
    );

    useEffect(() => {
        fetchUnitTypesData();
    }, [currentProperty]);

    useEffect(() => {
        if (unitTypesData && !unitTypesDataLoading) {
            let latestPMSUnitTypes: {list: UnitTypePMSInfo[], date: string;} | undefined = undefined;
            if (unitTypesData.fetchPMSUnitTypes.length > 0) {
                const maxSeenLatest = unitTypesData.fetchPMSUnitTypes.maxValue(ut => ut.seenLatest);
                latestPMSUnitTypes = {list: [], date: maxSeenLatest};
                for (const pmsUnitType of unitTypesData.fetchPMSUnitTypes) {
                    if (pmsUnitType.seenLatest === maxSeenLatest) {
                        latestPMSUnitTypes.list.push(pmsUnitType);
                    }
                }
            }
            setUnitTypesPMSLatest(latestPMSUnitTypes);
            let unitTypes: UnitTypeInfo[] = [];
            for (const unitTypeRaw of unitTypesData.listUnitTypes) {
                unitTypes.push({
                    ...unitTypeRaw,
                    inAlert: latestPMSUnitTypes && !latestPMSUnitTypes.list.find(ut => ut.name === unitTypeRaw.name) ? 1 : 0
                });
            }
            unitTypes = unitTypes.sort((a, b) => {
                if (a.inAlert === b.inAlert) {
                    return a.name.localeCompare(b.name);
                }
                return b.inAlert - a.inAlert;
            });
            setUnitTypes(unitTypes);
            setUnitTypesDisplay(unitTypes);
            if (unitTypesData.queryPropertyPMSActualsWithOverridesMonth) {
                const unitCountValues: MetricMap = new Map();
                const inPlaceRentValues: MetricMap = new Map();
                const occupancyValues: MetricMap = new Map();
                for (const value of unitTypesData.queryPropertyPMSActualsWithOverridesMonth.unitCount) {
                    unitCountValues.set(value.unitTypeId, dataValueToMapValue(value));
                }
                for (const value of unitTypesData.queryPropertyPMSActualsWithOverridesMonth.inPlaceRent) {
                    inPlaceRentValues.set(value.unitTypeId, dataValueToMapValue(value));
                }
                for (const value of unitTypesData.queryPropertyPMSActualsWithOverridesMonth.occupancy) {
                    occupancyValues.set(value.unitTypeId, dataValueToMapValue(value));
                }
                setMetricValues({
                    unitCount: unitCountValues,
                    inPlaceRent: inPlaceRentValues,
                    occupancy: occupancyValues
                });
            }
        }
    }, [unitTypesData, unitTypesDataLoading]);

    const tableBody = useMemo(() => {
        return unitTypesDisplay.map(unitType => {
            const unitCount = metricValues.unitCount.get(unitType.id);
            const occupancy = metricValues.occupancy.get(unitType.id);
            const inPlaceRent = metricValues.inPlaceRent.get(unitType.id);
            let unitCountOverriden = false;
            let occupancyOverriden = false;
            let inPlaceRentOverriden = false;
            let unitCountValue = null;
            let occupancyValue = null;
            let inPlaceRentValue = null;
            if (unitCount) {
                unitCountOverriden = unitCount.override !== null;
                unitCountValue = unitCount.override === null ? unitCount.original : unitCount.override;
            }
            if (occupancy) {
                occupancyOverriden = occupancy.override !== null;
                occupancyValue = occupancy.override === null ? occupancy.original : occupancy.override;
            }
            if (occupancyValue !== null) {
                occupancyValue *= 100;
            }
            if (inPlaceRent) {
                inPlaceRentOverriden = inPlaceRent.override !== null;
                inPlaceRentValue = inPlaceRent.override === null ? inPlaceRent.original : inPlaceRent.override;
            }
            return (
                <Row key={unitType.id}>
                    <Cell className={`${css.cell}`}>
                        <div className={css.content}>
                            <div className={css.nameCellBody}>
                                {unitType.inAlert == 1 ?
                                    <Tooltip
                                        content="Unit type name not found in PMS"
                                        popperModifiers={{
                                            flip: {
                                                boundariesElement: bodyRef.current ?? undefined
                                            },
                                        }}
                                    >
                                        <AlertIcon style={{color: "var(--yellow-200)"}} />
                                    </Tooltip>
                                    :
                                    undefined
                                }
                                <span>{unitType.name}</span>
                            </div>
                            <div className={css.controls}>
                                <Button
                                    isBasic
                                    onClick={() => setUpdateUnitTypeData(unitType)}
                                >
                                    <Tooltip
                                        content="Edit Name"
                                        popperModifiers={{
                                            flip: {
                                                boundariesElement: bodyRef.current ?? undefined
                                            },
                                        }}
                                    >
                                        <PencilIcon />
                                    </Tooltip>
                                </Button>
                                <Button
                                    isBasic
                                    onClick={() => setUpdateUnitTypeNumbers(unitType)}
                                >
                                    <Tooltip
                                        content="Override PMS Numbers"
                                        popperModifiers={{
                                            flip: {
                                                boundariesElement: bodyRef.current ?? undefined
                                            },
                                        }}
                                    >
                                        <Icon123 />
                                    </Tooltip>
                                </Button>
                                <Button
                                    isBasic
                                    isDanger
                                    onClick={() => setDeleteUnitTypeData(unitType)}
                                >
                                    <XIcon />
                                </Button>
                            </div>
                        </div>
                    </Cell>
                    <Cell className={`${css.cell} ${css.metricCell} ${unitCountOverriden ? css.isOverride : ""}`}>
                        {unitCountValue !== null ? unitCountValue : ""}
                    </Cell>
                    <Cell className={`${css.cell} ${css.metricCell} ${occupancyOverriden ? css.isOverride : ""}`}>
                        {occupancyValue !== null ? occupancyValue : ""}
                    </Cell>
                    <Cell className={`${css.cell} ${css.metricCell} ${inPlaceRentOverriden ? css.isOverride : ""}`}>
                        {inPlaceRentValue !== null ? inPlaceRentValue : ""}
                    </Cell>
                    <Cell className={`${css.cell} ${css.inUseCell}`}>{unitType.inUse ? <CheckIcon /> : ""}</Cell>
                    <Cell className={`${css.cell} ${css.periodCell}`}>{unitType.createdAt}</Cell>
                </Row>
            );
        });
    },
        [unitTypesDisplay]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Unit Types Management"}
                    subtitle={"Add/Rename/Delete Unit Types and override historical metric values"}
                />
                <Card
                    title={"Unit Types For Property"}
                    actions={
                        <>
                            {currentProperty && unitTypesData && !unitTypesDataLoading && !unitTypesData.findPMSProperty &&
                                <Button
                                    isDanger
                                    style={{marginRight: "0.5rem"}}
                                    onClick={handleIntercomMismatchingProperty}
                                >
                                    <Tooltip
                                        size="large"
                                        type="light"
                                        content={
                                            <>
                                                <span>{currentProperty.name} is not found in PMS.&nbsp;</span>
                                                <span>Operational metrics refresh is blocked for this property.</span>
                                            </>
                                        }
                                    >
                                        <AlertIcon style={{color: "var(--yellow-200)", marginRight: "0.5rem"}} />
                                    </Tooltip>
                                    Resolve Property Mismatch
                                </Button>
                            }
                            <Field className={css.inputBox}>
                                <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                                    if (!unitTypesData) {
                                        return;
                                    }
                                    setUnitTypesDisplay(unitTypes.filter(p => p.name.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                                }} />
                            </Field>
                            <Button
                                isBasic
                                onClick={() => setAddUnitTypeData(true)}
                            >
                                <PlusIcon />
                            </Button>
                        </>
                    }
                >
                    <div className={css.unitTypesTableWrapper}>
                        <Table className={css.unitTypesTable}>
                            <Head isSticky>
                                <HeaderRow className={css.headerCell}>
                                    <HeaderCell className={`${css.cell} ${css.nameCell}`}>Name</HeaderCell>
                                    <HeaderCell className={`${css.cell} ${css.metricCell}`}>Unit Count</HeaderCell>
                                    <HeaderCell className={`${css.cell} ${css.metricCell}`}>Occupancy %</HeaderCell>
                                    <HeaderCell className={`${css.cell} ${css.metricCell}`}>In Place Rent</HeaderCell>
                                    <HeaderCell className={`${css.cell} ${css.inUseCell}`}>Revenue Configured</HeaderCell>
                                    <HeaderCell className={`${css.cell} ${css.periodCell}`}>Added On</HeaderCell>
                                </HeaderRow>
                            </Head>

                            <Body ref={bodyRef}>
                                {tableBody}
                            </Body>
                        </Table>
                    </div>
                </Card>
            </div>
            {
                updateUnitTypeNumbers && currentProperty &&
                <EditUnitTypeNumbers
                    property={currentProperty}
                    unitTypeId={updateUnitTypeNumbers.id}
                    title="Review and Override PMS Numbers"
                    onCancel={() => setUpdateUnitTypeNumbers(undefined)}
                    onSubmit={(unitCountOverrides, occupancyOverrides, inPlaceRentOverrides) => handleUpdateUnitType(updateUnitTypeNumbers.id, undefined, unitCountOverrides, occupancyOverrides, inPlaceRentOverrides)}
                />
            }
            {
                updateUnitTypeData && currentProperty &&
                <EditUnitType
                    existingUnitTypes={unitTypesData?.listUnitTypes ?? []}
                    initialName={updateUnitTypeData.name}
                    pmsUnitTypes={unitTypesPMSLatest?.list}
                    pmsUnitTypesAsOfDate={unitTypesPMSLatest?.date}
                    unitTypeId={updateUnitTypeData.id}
                    title={`Edit Unit Type ${updateUnitTypeData.name}`}
                    onCancel={() => setUpdateUnitTypeData(undefined)}
                    onSubmit={(name) => handleUpdateUnitType(updateUnitTypeData.id, name, {}, {}, {})}
                />
            }
            {
                addUnitTypeData && currentProperty &&
                <EditUnitType
                    existingUnitTypes={unitTypesData?.listUnitTypes ?? []}
                    pmsUnitTypes={unitTypesPMSLatest?.list}
                    pmsUnitTypesAsOfDate={unitTypesPMSLatest?.date}
                    title="Add Unit Type"
                    onCancel={() => setAddUnitTypeData(false)}
                    onSubmit={(name) => currentProperty && handleAddUnitType(currentProperty.id, name)}
                />
            }
            {deleteUnitTypeData && currentProperty && (
                <DeleteUnitTypeModal
                    unitTypeId={deleteUnitTypeData.id}
                    unitTypeName={deleteUnitTypeData.name}
                    budgetYear={currentProperty.budgetYear}
                    onClose={() => {
                        setDeleteUnitTypeData(undefined);
                    }}
                    onConfirm={() => {
                        handleDeleteUnitType(deleteUnitTypeData.id);
                    }}
                />
            )}
        </ThemeProvider>
    );
}